import React, { useState } from "react";
import useAppContext from "context/state";
import { useSession } from "next-auth/client";

import NotificationAlert from "react-notification-alert";
import moment from "moment";
import axios from "axios";
import {
  topLevel,
  levelOneEntry,
  levelTwoEntry,
  levelThreeEntry,
  levelFourEntry,
  topLevelUser,
} from "components/Utility/treeReading";
import { Button, Row, Col, Input, Label, FormGroup } from "reactstrap";
import {defaultDataReducer} from "components/Reducers/dataReducers";
import DatePickerCalendar from "components/MainContent/DatePickerComponent";
import { useRouter } from "next/router";

function ManualTicket() { 
  const api = useAppContext();
  const { query } = useRouter();
  const [session, loading] = useSession();
  const [topItem, setTopITem] = React.useState(undefined)


    const [concatedItem, setConcatedItem] = React.useState({
    level0: "",
    level1: "",
    level2: "",
    level3: "",
    level4: "",
    level5: "",
  });
  const [otherReason, setOtherReason] = React.useState(false);
  const [selectedMeter, setSelectedMeter] = React.useState("");
  const [selectedIssueDay, setSelectedIssueDay] = React.useState(null);
  const [selectedEndDay, setSelectedEndDay] = React.useState(null);
  const [selectedDueDay, setSelectedDueDay] = React.useState(null);
  const [usersList, setUsersList] = React.useState([]);
  const [closeReasonsList, setCloseReasonList] = React.useState([]);
  const [description, setDescription] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [affectedCapacity, setAffectedCapacity] = React.useState(0);
  const [meterCapacity, setMeterCapacity] = React.useState(null);
  const [assigned, setAssigned] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [closeReason, setCloseReason] = React.useState("");
  const [ticketType, setTicketType] = React.useState("");
   const notificationAlertRef = React.useRef(null);
  const [closeReasonComment, setCloseReasonComment] = React.useState("");
  const [issueTypeList, setIssueTypeList] = React.useState([]);
  const [gadsIssueTypeList,setGadsIssueTypeList] = React.useState([]);
  const [gadsCauseCodeList, setGadsCauseCodeList] = React.useState([]);
  const [defaultStartDate, setDefaultStartDate] = React.useState(moment().format('YYYY-MM-DDTHH:mm'));
  const [defaultEndDate, setDefaultEndDate] = React.useState(moment().format('YYYY-MM-DDTHH:mm'));
  const [isGadsSelected, setIsGadsSelected] = useState(false);
  const [ gadsIssueType, setGadsIssueType] = useState('');
  const [selectCauseCode, setSelectCauseCode] = useState('');
  const [codeCauseSelected, setCodeCauseSelected] = useState(false);


  const tstartDateRef = React.useRef()
  const tendDateRef = React.useRef()
  const setIssueDay = (e) => {
    e.stopPropagation();
    setSelectedIssueDay(e.target.value);
  
  };
  console.log(selectedIssueDay);
  const setEndDay = (e) => {
    e.stopPropagation();
    
    setSelectedEndDay(e.target.value);
  };
  console.log(selectedEndDay);
  React.useEffect(() => {
    if (selectedIssueDay) {
      setDefaultStartDate(selectedIssueDay);
    }
  }, [selectedIssueDay]);
  
  React.useEffect(() => {
    if (selectedEndDay) {
      setDefaultEndDate(selectedEndDay);
    }
  }, [selectedEndDay]);

  console.log("time:"+ tstartDateRef)
  console.log(tstartDateRef)

  const [defaultData, dispatchDefaultData] = React.useReducer(
    defaultDataReducer,
    {
        data: {plant_timezone: "Asia/Kolkata"},
        isLoading: false,
        isError: false,
    }
);



  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  // React.useEffect(async () => {
  //   if (topItem!= undefined && topItem != "") {
  //     try {
  //       const result = await axios.get(`/api/audit/getFailureReason`, {
  //         params: {
  //           plant_id: topItem,
  //         },
  //       });
  //       setCloseReasonList(result.data.data);
  //     } catch (e) { console.log(e)}
  //   }
  // }, [topItem]);



  React.useEffect(() => {
    async function fetchData() {
      if(topItem!=undefined && topItem!="") {
        const payload = { plant: topItem};
        const  result  = await  axios.post(`/api/operations/plantUsersList`, payload )
        setUsersList(result.data.data);
      }

    }
    fetchData();
  }, [topItem]); // Or [] if effect doesn't need props or state



// This piece of code was giving Error in the manual ticket page

  // React.useEffect(async () => {
  //   try {
  //     const payload = {
  //       plant_id: api.plant_id,
  //       data_group: "tickets",
  //       p_name: "manual",
  //     };
  //     const result = await axios.post(`/api/operations/metadata`, payload);
  //     setIssueTypeList(result.data.data);
  //   } catch (e) {
  //     console.log("error", e);
  //   }
  // }, [api.plant_id]);
  

//  Have made the following changes in the code to fix this issue

  React.useEffect(() => {
    const fetchIssueTypeList = async () => {
      try {
        const payload = {
          plant_id: api.plant_id,
          data_group: "tickets",
          p_name: "manual",
        };
        const result = await axios.post(`/api/operations/metadata`, payload);
        const GADS = [{
          "id": 0,
          "plant_id": 900,
          "data_group": "tickets",
          "p_name": "manual",
          "p_value": "GADS"
      }];
        setIssueTypeList(result.data.data.concat(GADS));
      } catch (e) {
        console.log("error", e);
      }
    };

    const fetchGadsIssueTypeList = async () => {
      try {
        const payload = {
          plant_id: query.plant_id,
          data_group: "tickets",
          p_name: "GADS_ISSUE_TYPE",
        };
        const result = await axios.post(`/api/operations/metadata`, payload);
        setGadsIssueTypeList(result.data.data);
      } catch (e) {
        console.log("error", e);
      }
    };

    const fetchGadsCauseCodeList = async () => {
      try {
        const payload = {
          plant_id: query.plant_id,
          data_group: "tickets",
          p_name: "GADS_CAUSE_CODE",
        };
        const result = await axios.post(`/api/operations/metadata`, payload);
        setGadsCauseCodeList(result.data.data);
      } catch (e) {
        console.log("error", e);
      }
    };
  
    fetchIssueTypeList();
    fetchGadsIssueTypeList();
    fetchGadsCauseCodeList();
  }, [api.plant_id]);

  const submitManualTicket = async (e) => {
    const plant = concatedItem.level0;
    var meter = selectedMeter;
    /*if (concatedItem.level1 != "") {
      meter = concatedItem.level1
    }
    if (concatedItem.level2 != "") {
      meter = concatedItem.level2
    }
    if (concatedItem.level3 != "") {
      meter = concatedItem.level3
    }
    if (concatedItem.level4 != "") {
      meter = concatedItem.level4
    }
    if (concatedItem.level5 != "") {
      meter = concatedItem.level5
    }*/

    // let issueDate = new Date(
    //   selectedIssueDay.year +
    //     "-" +
    //     selectedIssueDay.month +
    //     "-" +
    //     selectedIssueDay.day
    // );

    // let endDate = new Date(
    //   selectedEndDay.year +
    //   "-" +
    //   selectedEndDay.month +
    //   "-" +
    //   selectedEndDay.day
    // )

    try {
      const payload = {
        plant_id: plant,
        ticket_type: ticketType,
        meter_id: meter,
        // start_date_wt: (selectedIssueDay.unix()),
        // end_date_wt: (endDate.getTime() / 1000).toFixed(0),
        start_date_wt: moment(tstartDateRef.current.value).unix(),
        end_date_wt : moment(tendDateRef.current.value).unix(),

        status: assigned != "" ? "ASSIGNED" : status,
        affected_capacity: affectedCapacity,
        //due_date: (dueDate.getTime() / 1000).toFixed(0),
        user: session.user.email,
        description: description,
        severity: severity,
        assigned_to: assigned,
        comment: comment,
        //close_reason_id: closeReason,
        close_reason_comment: closeReasonComment,
        //issue_type: "manual"
      };
      console.log(payload); // Log the payload object
      const result = await axios.post(
        `/api/operations/createManualTicket`,
        payload
      );
      console.log(result); // Log the result object
      notify("success", "Ticket Created Successfully", " *Note");
    } catch (e) {
      notify("danger", "Manual Ticket Not Created", "error");
    }
  };

  const handleCloseReason = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    if (
      ["Other"].includes(
        e.target.options[selectedIndex].getAttribute("data-key")
      )
    ) {
      setOtherReason(true);
    } else {
      setOtherReason(false);
    }
    setCloseReason(e.target.value);
  };

  const handleIssueCloseReasonComment = (e) => {
    setCloseReasonComment(e.target.value);
  };

  const handleStatusSelection = (e) => {
    setStatus(e.target.value);
  };

  const handleTicketTypeChange = (e) => {
    setTicketType(e.target.value);
    setIsGadsSelected(e.target.value === "GADS");
  };

  const handleGadsIssueTypeChange = (e) => {
    setGadsIssueType(e.target.value);
    setCodeCauseSelected(true);
  };

  const handleCauseCodeChange = (e) => {
    setSelectCauseCode(e.target.value);
  };

  const handleAssigneeChange = (e) => {
    setAssigned(e.target.value);
  };

  const handleAffectedCapacity = (e) => {
    setAffectedCapacity(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSeverityChange = (e) => {
    setSeverity(e.target.value);
  };


  const handleTopSelection = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const plant_meter = e.target.value.split("_");
    if(topItem!=plant_meter[0] ) {
      setTopITem(plant_meter[0])
    }

    setConcatedItem({
      ...concatedItem,
      level0: plant_meter[0],
      level1: "",
      level2: "",
      level3: "",
      level4: "",
      level5: "",
    });
    setSelectedMeter(plant_meter[1]);
  };

  const handleLevelOneSelection = (e) => {
    e.preventDefault();
    setConcatedItem({
      ...concatedItem,
      level1: e.target.value,
      level2: "",
      level3: "",
      level4: "",
      level5: "",
    });
    setSelectedMeter(e.target.value);
  };

  const handleLevelTwoSelection = (e) => {
    e.preventDefault();
    setConcatedItem({
      ...concatedItem,
      level2: e.target.value,
      level3: "",
      level4: "",
      level5: "",
    });
    setSelectedMeter(e.target.value);
  };

  const handleLevelThreeSelection = (e) => {
    e.preventDefault();
    setConcatedItem({
      ...concatedItem,
      level3: e.target.value,
      level4: "",
      level5: "",
    });
    setSelectedMeter(e.target.value);
  };

  const handleLevelFourSelection = (e) => {
    e.preventDefault();
    setConcatedItem({
      ...concatedItem,
      level4: e.target.value,
      level5: "",
    });
    setSelectedMeter(e.target.value);
  };

  const handleLevelFiveSelection = (e) => {
    e.preventDefault();
    setConcatedItem({
      ...concatedItem,
      level5: e.target.value,
    });
    setSelectedMeter(e.target.value);
  };

  // React.useEffect(async () => {
  //   if (selectedMeter != "" && concatedItem.level0 != "") {
  //     const payload = {
  //       meter_id_list: [selectedMeter],
  //       plant_id: concatedItem.level0,
  //     };
  //     const result = await axios.post(`/api/operations/meterCapacity`, payload);
  //     setMeterCapacity(result.data.data[0].capacity);
  //     setAffectedCapacity(result.data.data[0].capacity);
  //   }
  // }, [selectedMeter, concatedItem.level0]);

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Row className="justify-content-between">
        {/* <Col xl="6"></Col> */}
        {/* <Label>Select Issue Date</Label>
          <DatePickerCalendar parenName="manualTicket" day={selectedIssueDay} dayHandler={setSelectedIssueDay}/>
        </Col> */}

        <Col>
        <FormGroup>
          <label htmlFor="testDateStart" >
            Select Issue Date
          </label>
          <Input
            id="testDateStart"
            placeholder="description"
            type="datetime-local"
            className=" form-control-sm"
            required
            innerRef={tstartDateRef}
            value={defaultStartDate}
            onChange={setIssueDay}
          ></Input>
        </FormGroup>
        </Col>
        <Col>

        <FormGroup>
          <label htmlFor="testDateEnd" >
            Select End Date
          </label>
          <Input
            id="testDateEnd"
            placeholder="description"
            type="datetime-local"
            className=" form-control-sm"
            required
            innerRef={tendDateRef}
            value={defaultEndDate}
            onChange={setEndDay}
          ></Input>
        </FormGroup>
        </Col>
      </Row>
      <div className="clearfix mt-2"></div>
      <div className="row">
        <Label style={{ width: "100%", paddingLeft: "15px" }}>
          Select Plant and Equipment
        </Label>
        <div className="col-md-6 mb-2">
          <Input
            type="select"
            name="selectmember"
            className="custom-select mb-0"
            onChange={handleTopSelection}
          >
            {topLevel().length > 0 ? (
              <>
                <option hidden>Select</option>
                {topLevelUser(session).map((item) => (
                  <option value={item.id + "_" + item.meter_id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </>
            ) : null}
          </Input>
        </div>
        <div className="col-md-6 mb-2">
          {concatedItem.level0 == "" ? null : (
            <Input
              type="select"
              name="selectmember"
              className="custom-select mb-0"
              onChange={handleLevelOneSelection}
            >
              {levelOneEntry(concatedItem.level0).length > 0 ? (
                <>
                  <option hidden>Select</option>
                  {levelOneEntry(concatedItem.level0).map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </>
              ) : null}
            </Input>
          )}
        </div>
        <div className="col-md-6 mb-2">
          {concatedItem.level1 == "" ? null : (
            <Input
              type="select"
              name="selectmember"
              className="custom-select mb-0"
              onChange={handleLevelTwoSelection}
            >
              {levelTwoEntry(concatedItem.level0, concatedItem.level1).length >
              0 ? (
                <>
                  <option hidden>Select</option>
                  {levelTwoEntry(concatedItem.level0, concatedItem.level1).map(
                    (item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    )
                  )}
                </>
              ) : null}
            </Input>
          )}
        </div>
        <div className="col-md-6 mb-2">
          {concatedItem.level2 == "" ? null : (
            <Input
              type="select"
              name="selectmember"
              className="custom-select mb-0"
              onChange={handleLevelThreeSelection}
            >
              {levelThreeEntry(
                concatedItem.level0,
                concatedItem.level1,
                concatedItem.level2
              ).length > 0 ? (
                <>
                  <option hidden>Select</option>
                  {levelThreeEntry(
                    concatedItem.level0,
                    concatedItem.level1,
                    concatedItem.level2
                  ).map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </>
              ) : null}
            </Input>
          )}
        </div>{" "}
        <div className="col-md-6 mb-2">
          {concatedItem.level3 == "" ? null : (
            <Input
              type="select"
              name="selectmember"
              className="custom-select mb-0"
              onChange={handleLevelFourSelection}
            >
              {levelFourEntry(
                concatedItem.level0,
                concatedItem.level1,
                concatedItem.level2,
                concatedItem.level3
              ).length > 0 ? (
                <>
                  <option hidden>Select</option>
                  {levelFourEntry(
                    concatedItem.level0,
                    concatedItem.level1,
                    concatedItem.level2,
                    concatedItem.level3
                  ).map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </>
              ) : null}
            </Input>
          )}
        </div>
      </div>
      <div className="clearfix"></div>
      <Label>Issue Description</Label>
      <FormGroup>
        <Input
          aria-label="With textarea"
          onChange={handleDescriptionChange}
        ></Input>
      </FormGroup>

      <div className="row">
        {/*<div className="col-md-6">
          <Label>Ticket Reason</Label>
          <Input
            type="select"
            name="selectmember"
            className="custom-select mb-0"
            onChange={handleCloseReason}
          >
            <>
              <option hidden>Select</option>
              {closeReasonsList.map((item) => <option value={item.id} key={item.id} data-key={item.p_value}>{item.p_value}</option>)}
            </>
          </Input>
        </div>*/}

        {otherReason == true ? (
          <div className="col-md-6">
            <Label>Add Other Reason</Label>
            <FormGroup>
              <Input
                aria-label="With textarea"
                onChange={handleIssueCloseReasonComment}
              ></Input>
            </FormGroup>
          </div>
        ) : null}
        <div className="col-md-6">
          <Label>Status</Label>
          <Input
            type="select"
            name="selectmember"
            className="custom-select mb-0"
            onChange={handleStatusSelection}
          >
            <>
              <option hidden>Select</option>
              <option value="OPEN">Open</option>
              <option value="CLOSED">Closed</option>
            </>
          </Input>
        </div>
      </div>
      <div className="clearfix mt-2"></div>
      <div className="row">
        <div className="col-md-6">
          <Label>Severity</Label>
          <Input
            type="select"
            name="selectmember"
            className="custom-select mb-0"
            onChange={handleSeverityChange}
          >
            <>
              <option hidden>Select</option>
              <option value="Major">Major</option>
              <option value="Minor">Minor</option>
              <option value="Critical">Critical</option>
            </>
          </Input>
        </div>
        <div className="col-md-6">
          <Label>Assign To</Label>
          <Input
            type="select"
            name="selectmember"
            className="custom-select mb-0"
            onChange={handleAssigneeChange}
          >
            <>
              <option hidden>Select</option>

              {usersList.map((item) => (
                <option value={item.email_id} key={item.id}>
                  {item.email_id}
                </option>
              ))}
            </>
          </Input>
        </div>
        <div className="col-md-6">
          <Label>Select Issue Type</Label>
          <Input
            type="select"
            name="selectmember"
            className="custom-select mb-0"
            onChange={handleTicketTypeChange}
          >
            <>
              <option hidden>Select</option>
              {issueTypeList.map((item) => (
                <option value={item.p_value} key={item.p_value}>
                  {item.p_value}
                </option>
              ))}
            </>
          </Input>
        </div>
        {isGadsSelected && (
          <div className="col-md-6">
          <Label>Select GADS Issue Type</Label>
          <Input
            type="select"
            name="selectmember"
            className="custom-select mb-0"
            onChange={handleGadsIssueTypeChange}
          >
            <>
              <option hidden>Select</option>
              {gadsIssueTypeList.map((item) => (
                <option value={item.p_value} key={item.p_value}>
                  {item.p_value}
                </option>
              ))}
            </>
          </Input>
        </div>
        )}
        {codeCauseSelected && (
          <div className="col-md-6">
          <Label>Select Cause Code</Label>
          <Input
            type="select"
            name="selectmember"
            className="custom-select mb-0"
            onChange={handleCauseCodeChange}
          >
            <>
              <option hidden>Select</option>
              {gadsCauseCodeList.map((item) => (
                <option value={item.p_value} key={item.p_value}>
                  {item.p_value}
                </option>
              ))}
            </>
          </Input>
        </div>
        )}
      </div>

      <div className="clearfix mt-2"></div>
      {/*<Label>Action Taken</Label>
      <FormGroup className="mb-2">
          <Input aria-label="With textarea" onChange={handleCommentChange}></Input>
      
      </FormGroup>*/}

      <Label>Affected Capacity</Label>
      <FormGroup className="mb-2">
        <Input
          placeholder={meterCapacity}
          aria-label="With textarea"
          onChange={handleAffectedCapacity}
        ></Input>
      </FormGroup>
      <Button
        className="mt-3 float-right"
        color="primary"
        type="button"
        onClick={submitManualTicket}
      >
        Submit
      </Button>
    </>
  );
}
export default ManualTicket;
