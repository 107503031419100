import React, {useState} from "react";
import { getSession } from "next-auth/client";
import Admin from "layouts/Admin.js";
import useAppContext from "context/state";
import trim_tree from "plant_variables/trim_tree.json";
import {
  Button,
  Card,
  CardBody,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { signIn, signOut, useSession } from "next-auth/client";
import { GeoMapDraw } from "components/MainContent/geoMapPlot";
import {useRouter} from "next/router";


function Login() {
  const router = useRouter();
  const api = useAppContext();


  const [customer_login,setCustomerLogin] = useState(process.env.CUSTOMER_LOGIN==undefined?false:process.env.CUSTOMER_LOGIN.toLowerCase() =="true")
  const [start_url,setStartUrl] = useState(process.env.start_url)

  var start_d = new Date();
  start_d.setHours(0, 0, 0, 0);
  var end_d = new Date();
  end_d.setHours(23, 55, 59, 0);
  const [session, loading] = useSession();



  React.useEffect(()=>{if(session){
    const treeObj = {
      ...trim_tree,
      nodes: trim_tree["nodes"].filter((value) =>
        session.user.plants["USER"].includes(value["plant_id"])
      ),
    };
    const {pathname} = router

    if (treeObj.nodes.length === 0) {
      signOut({ callbackUrl: '/' });
      return;
    }

    if(pathname == '/' ){
      if(typeof api !== 'undefined') {
        router.push(`${session.user.plants.LANDING_PAGE!=undefined?session.user.plants.LANDING_PAGE:start_url}?plant_id=${api.plant_id}&meter_id=${api.currentMeter}&start_date=${api.startDate}&end_date=${api.endDate}&show_global=0&sv=0&ev=0&st=0&show_unnormalized=0&derived_value=Pwr_Norm&all_siblings=0&graph_mode=meterDiagnosis&zs=&ze=&reading_type=`)
      } else {

        router.push(`${session.user.plants.LANDING_PAGE!=undefined?session.user.plants.LANDING_PAGE:start_url}?plant_id=${treeObj["nodes"][0]["plant_id"]}&meter_id=${treeObj["nodes"][0]["meter_id"]}&start_date=${Math.round(start_d.getTime() / 1000)}&end_date=${Math.round(end_d.getTime() / 1000)}&show_global=0&sv=0&ev=0&st=0&show_unnormalized=0&derived_value=Pwr_Norm&all_siblings=0&graph_mode=meterDiagnosis&zs=&ze=&reading_type=`)
      }
    }
  }},[])
  return (
    <>
      {!session && (
        <>
          <AuthHeader title="Welcome to SolarDash!" lead="" />
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <Card className="bg-secondary border-0 mb-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      {!session && (
                        <>
                          <small>Sign in with Azure AD </small>
                        </>
                      )}
                    </div>
                    <Form role="form">
                      <div className="text-center">
                        {!session && (
                          <>
                            {customer_login==true?<>
                              <Button
                                  className="my-4"
                                  color="info"
                                  type="button"
                                  onClick={() =>
                                      signIn("azure-ad-b2c", {}, { prompt: "login" })
                                  }
                              >
                                Customer Sign in
                              </Button>
                            </>:null }

                            <Button
                              className="my-4"
                              color="info"
                              type="button"
                              onClick={() =>
                                signIn("azure-ad", {}, {  })
                              }
                            >
                              AD Sign in
                            </Button>
                          </>
                        )}
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}

      {session && (
        <>



          <style jsx global>
            {`
              .backtohome {
                position: absolute;
                right: 2rem;
                top: 1rem;
                color: #767676;
                font-weight: 600;
              }
              .footer {
                display: none;
              }
              .backdrop {
                display: none;
              }
              .carddivportfolio1 {
                max-height: calc(100vh - 9rem) !important;
              }
            `}
          </style>
        </>
      )}
    </>
  );
}

export async function getServerSideProps(ctx) {
  const session = await getSession(ctx);
  return { props: { session} };
}

Login.layout = Admin;

export default Login;
